import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../components/_App/layout"
import Navbar from "../components/_App/Navbar"
import Header1 from "../components/Headers/Header1"
import Footer from "../components/_App/Footer"

const Policy = ({ data }) => {
  const { seo, template } = data.page

  return (
    <Layout>
      <Navbar seo={seo} />

      {template.contents.map(content => {
        switch (content.strapi_component) {
          case "headers.text-only":
            return <Header1 title={content.title} />
          case "terms.terms":
            return (
              <div className="contact-area ptb-80">
                <div className="container">
                  <p className="terms-body">
                    <ReactMarkdown source={content.terms.data} />
                  </p>
                </div>
              </div>
            )
          default:
            return ""
        }
      })}

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetPolicyPageDetails($id: String) {
    page: strapiPage(id: { eq: $id }) {
      title
      seo {
        metaTitle
        metaDescription
        canonicalURL
        metaRobots
        keywords
        ogTitle
        ogDescription
        ogMetaImage {
          url
        }
        ogURL
        ogType
        twitterTitle
        twitterDescription
        twitterImage {
          url
        }
        twitterSite
        twitterCard
        structuredData {
          data {
            structuredData
          }
        }
      }
      template {
        slug
        contents {
          strapi_component
          title
          terms {
            data
          }
        }
      }
    }
  }
`

export default Policy